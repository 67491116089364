<template>
  <div class="resource">
    <div class="breadcrumb w-1400">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/resource' }"
          >资源集市</el-breadcrumb-item
        >
        <el-breadcrumb-item
          :to="{ path: '/resource' }"
          v-if="$route.query.type == 1"
          >盘活资源</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/equity' }" v-else
          >产权交易</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ pageData.title }}详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content w-1400">
      <div class="h4"></div>
      <div class="f1">
        <el-row :gutter="20">
          <el-col :span="15">
            <div class="photo">
              <div class="photo-pic" v-if="pageData.photoUrls">
                <el-carousel
                  trigger="click"
                  height="480px"
                  :interval="5000"
                >
                  <el-carousel-item
                    v-for="item in pageData.photoUrls.split(',')"
                    :key="item+'url'"
                  >
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="baseUrl + item"
                    ></el-image>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <!-- <ul class="photo-p-list">
                <li class="p-item act" v-if="pageData.photoUrls">
                  <div class="xubi"></div>
                  <div>
                    室内图（{{ pageData.photoUrls.split(",").length }}）
                  </div>
                </li>
                <li class="p-item">
                  <div class="xubi"></div>
                  <div>户型图（0）</div>
                </li>
                <li class="p-item">
                  <div class="xubi"></div>
                  <div>环境图（0）</div>
                </li>
                <li class="p-item">
                  <div class="xubi"></div>
                  <div>周边地图（0）</div>
                </li>
              </ul> -->
            </div>
          </el-col>
          <el-col :span="9">
            <div class="f1-info">
              <div class="info-h3">{{ pageData.price }} 元/月</div>
              <div class="infos" v-if="$route.query.type == 1">
                <p>发布时间：{{pageData.createdTime}}</p>
                <p>出租期限：{{pageData.rentYears}}年</p>
                <p>出租方式：{{pageData.rentMode}}</p>
              </div>
              <div v-else class="infos">
                <p>成交时间：{{$route.query.rt}}</p>
                <p>出租期限：{{$route.query.cycle}}年</p>
                <p>成交渠道：{{$route.query.way}}</p>
                <p>出租方式：{{$route.query.mode}}</p>
              </div>
              <div class="info-text">
                所在地区：{{ pageData.regionName}}
              </div>
              <div class="info-text">适用用途：{{ pageData.suitableUses }}</div>
              <div class="info-text">农房编号：{{ pageData.farmhouseNo }}</div>
              <div class="market row" v-if="pageData.features">
                <div v-for="item in pageData.features.split(',')" :key="item">
                  {{ item }}
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="f2 mt-20">
        <el-row :gutter="30">
          <el-col :span="18">
            <div class="zc-title">基本信息</div>
            <div class="zc-info">
              <table>
                <tr>
                  <td>户型： {{ pageData.houseLayout }}</td>
                  <td>面积： {{ pageData.floorArea }}m²</td>
                  <td>朝向： {{ pageData.orientation }}</td>
                </tr>
                <tr>
                  <td>楼层： {{ pageData.floorNumber }}</td>
                  <td>装修： {{ pageData.decoration }}</td>
                  <td>类型： {{ pageData.houseType }}</td>
                </tr>
                <tr>
                  <td colspan="3">小区：</td>
                </tr>
              </table>
            </div>
            <div class="pz">
              <h4 class="z-h4">配套设施</h4>
              <div class="pz-box">
                <div class="pz-item" v-for="(item,key,index) in pzList" :key="item + index" :class="{own:pageData.facilities.indexOf(key)>-1}">
                  <div class="iconbox iconfont" :class="`icon-${item}`"></div>
                  <div class="pz-name">{{ key }}</div>
                </div>
              </div>
            </div>
            <!-- <div class="gk">
              <h4 class="z-h4">房屋概况</h4>
              <div class="gk-box">
                <p>1.房屋条件优越，楼层适中、整体干净整洁，全新精美装修。</p>
                <p>2.正规居民楼，格局规整，朝南向，采光好，无遮挡。</p>
                <p>3.家电家具齐全，无线WiFi，室内配床，衣柜，电脑桌等。</p>
                <p>4.地理位置优越，交通便利，周边生活设施齐全。</p>
              </div>
            </div> -->
            <div class="house mt-20">
              <h4 class="z-h4">地理位置</h4>
              <div class="position">
                <arc-map :title="pageData.title" :center="[pageData.longitude, pageData.latitude]"></arc-map>
                <!-- <baidu-map
                  class="map"
                  :center="{ lng: pageData.longitude, lat: pageData.latitude }"
                  :scroll-wheel-zoom="true"
                  :zoom="14"
                >
                  <bm-marker
                    :position="{
                      lng: pageData.longitude,
                      lat: pageData.latitude,
                    }"
                    :dragging="true"
                  >
                    <bm-label
                      :content="pageData.title"
                      :labelStyle="{
                        color: 'skyblue',
                        fontSize: '16px',
                        border: '1px solid skyblue',
                        transform: 'translateX(-48%)',
                      }"
                      :offset="{ width: 0, height: 30 }"
                    />
                  </bm-marker>
                </baidu-map> -->
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <!-- 附近景点-->
            <div class="advertising mt-20">
              <div class="advertising-h3 clearfix">
                <div class="fl">附近热门景点</div>
                <router-link to="/scenerySpotList">
                  <div class="fr more">
                    更多<i class="el-icon-arrow-right"></i>
                  </div>
                </router-link>
              </div>
              <div class="advertising-main">
                <div
                  class="advertising-itme clearfix"
                  v-for="item in ScenerySpotPageData"
                  :key="item.id + 'near'"
                >
                   <router-link :to="`/scenerySpotdetail?id=${item.id}`">
                  <div class="advertising-itme-pic fl">
                 
                      <el-image
                        style="width: 100%; height: 100%"
                        :src="item.photoUrls.split(',')[0]"
                      ></el-image>
                  </div>
                  <div class="item-info fr">
                    <div class="name">名称：{{ item.name }}</div>
                    <div class="score">星级：{{ item.grade }}</div>
                    <div class="price buy">
                      价格：{{ item.price }}元
                    </div>
                    <div class="market2">
                      <span>游湖</span>
                      <span>棋牌</span>
                    </div>
                  </div>
                   </router-link>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { GetOneRentInfos } from "@/api/ziyuanjishi";
import { GetScenerySpotPage } from "@/api/home";
import { mapState } from "vuex";
export default {
  computed:{
    ...mapState({
      lonR:a=>a.lonR,
      latR:a=>a.latR,
    })
  },
  data() {
    return {
      classify: ["中介/服务", "传统制造", "策划/咨询", "贸易零售", "其他"],
      region: [
        "全西宁",
        "城北区",
        "城西区",
        "城东区",
        "城南区",
        "湟中区",
        "平安区",
        "乐都区",
        "西宁周边",
      ],
      nature: [
        "有限责公司",
        "股份有限公司",
        "国有企业",
        "私营企业",
        "外资企业",
        "其他",
      ],
      pzList: {
        '冰箱':'bingxiang',
        '电视':'dianshi',
        '洗衣机':'xiyiji',
        '热水器':'reshuiqi',
        '空调':'kongtiao1',
        '宽带':'kuandai',
        '沙发':'shafa',
        '床':'chuang',
        '暖气':'nuamqi',
        '衣柜':'yigui',
        '可做饭':'kezuofan',
        '卫生间':'weishengjian',
        '阳台':'yantai',
        '智能门锁':'zhinengmensuo',
        '油烟机':'youyanji',
        '燃气灶':'yanqizao',
      },
      pageData: {photoUrl:"",facilities:''},
      params: {
        currentPage: 1,
        pageSize: 4,
        filter: {
          name: "",
        },
      },
      ScenerySpotPageData: {},
    };
  },
  computed: {
    baseUrl: () => process.env.VUE_APP_PHOTO_URL,
  },
  methods: {
    getDetail() {
      GetOneRentInfos(this.$route.query.id).then((res) => {
        this.pageData = res.data.data;
        // this.devList=this.devList.filter((item)=>
        //   res.data.data.facilities.split(",").includes(item.title)
        // );
        this.getScenerySpotPage(this.pageData);
      });
    },
    getScenerySpotPage(obj) {
      const params = {
        currentPage: 1,
        pageSize: 5,
        filter: {
          longitude: obj.longitude,
          longitudeR: this.lonR,
          latitude: obj.latitude,
          latitudeR: this.latR
        },
      }
      GetScenerySpotPage(params).then((res) => {
        this.ScenerySpotPageData = res.data.data.list;
      });
    },
  },
  mounted() {
    this.getDetail();
  },
};
</script>
<style scoped>
.map {
  height: 500px;
}
.market2 span:nth-child(1) {
  border: 1px solid #f9773b;
  color: #f9773b;
}
.market2 span:nth-child(2) {
  border: 1px solid #1c91ff;
  color: #1c91ff;
}
.market2 span:nth-child(3) {
  border: 1px solid #33d448;
  color: #33d448;
}
.market2 span {
  padding: 0 8px;
  margin-right: 12px;
}
.score {
  color: #f9773b;
}
.market span {
  padding: 0 8px;
  background: #1c91ff;
  color: #fff;
  margin-left: 12px;
}
.item-info div.buy {
  color: #0169ca;
}
.item-info div {
  height: 26px;
  line-height: 26px;
  font-size: 16px;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.item-info {
  width: calc(100% - 125px);
}
.advertising-main {
  padding: 0 10px;
  border: 1px solid #666;
}
.more:hover {
  color: #eee;
}
.more {
  cursor: pointer;
  font-size: 16px;
}
.advertising-h3 {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #fff;
  background: #0169ca;
  padding: 0 6px;
}
.advertising-itme {
  margin-top: 12px;
  overflow: hidden;
  width: 100%;
  height: 120px;
}
.advertising-itme-pic {
  width: 120px;
  height: 100px;
}
.map-box {
  padding: 10px;
}
.gk-box p {
  line-height: 30px;
  font-size: 16px;
}
.gk-box {
  padding: 10px 20px;
}
.iconbox.iconfont{
  font-size: 64px;
}
.pz-item{
  width: 12.5%;
  color: #cfcfcf;
  text-align: center;
}
.pz-item.own{
  color: #00a0e9;
}
.pz-box {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
h4.z-h4 {
  font-size: 18px;
  line-height: 30px;
  border-bottom: 1px solid #ccc;
}
.zc-info table td {
  height: 32px;
}
.zc-info {
  padding: 20px;
}
.zc-info table {
  width: 100%;
  font-size: 16px;
}
.zc-title {
  font-size: 18px;
  line-height: 36px;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
}
.market div:nth-child(1) {
  background: #f40000;
}
.market div:nth-child(2) {
  background: #f89807;
}
.market div:nth-child(3) {
  background: #1fb59a;
}
.market div:nth-child(4) {
  background: #00a0e9;
}
.market div:nth-child(5) {
  background: #32b16c;
}
.market div:nth-child(6) {
  background: #0b58c2;
}
.market div {
  /* padding: 10px 20px; */
  width: 105px;
  line-height: 36px;
  margin: 10px;
  font-size: 16px;
  text-align: center;
  color: #fff;
  border-radius: 3px;
}
.market {
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
}
.info-text {
  font-size: 15px;
  font-weight: 600;
  line-height: 36px;
}
.infos {
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
  line-height: 36px;
}
.info-h3 {
  font-size: 22px;
  font-weight: 600;
  color: #f40000;
  line-height: 50px;
}
.xubi {
  width: 80px;
  height: 60px;
  margin: 20px auto 10px;
  background: url(../../../assets/img/zc1.png) no-repeat center center;
}
.p-item:nth-child(1) .xubi {
  background: url(../../../assets/img/zc1.png) no-repeat center center;
}
.p-item:nth-child(2) .xubi {
  background: url(../../../assets/img/zc2.png) no-repeat center center;
}
.p-item:nth-child(3) .xubi {
  background: url(../../../assets/img/zc3.png) no-repeat center center;
}
.p-item:nth-child(4) .xubi {
  background: url(../../../assets/img/zc4.png) no-repeat center center;
}
.p-item.act {
  background: #fff;
}
.p-item {
  width: 140px;
  height: 120px;
  text-align: center;
  border: 1px solid #999;
  border-radius: 3px;
  box-sizing: border-box;
  background: #ddd;
}
.photo-p-list {
  position: absolute;
  top: 0;
  left: 0;
}
.photo {
  height: 480px;
  border: 1px solid #999;
  position: relative;
}
.position{
  width: 100%;
  height: 480px;
}
.h4 {
  line-height: 42px;
  font-size: 20px;
  font-weight: 600;
}
.resource {
  font-family: "微软雅黑";
  /* background: url(../../../assets/img/qylist-bg.png) no-repeat; */
  background-size: auto 300px;
}
.breadcrumb >>> .el-breadcrumb .is-link,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner {
  font-size: 18px;
  font-family: "微软雅黑";
  color: #0e6ac1;
}
.breadcrumb {
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #0e6ac1;
  margin-bottom: 50px;
}
</style>
